import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useQuery, useMutation, gql } from "@apollo/client";
import AddFamilyMemberForm from "../components/FamilyMemberForm";
import MemberDetails from "../components/MemberDetails";
import Search from "../components/Search";
import { IoMdClose } from "react-icons/io";
import { FaUserPlus } from "react-icons/fa6";
import loadingImg from "../images/loading.gif";

Modal.setAppElement("#root");

const GET_MEMBERS = gql`
  query GetMembers($page: Int, $pageSize: Int) {
    familyMembers(pagination: { page: $page, pageSize: $pageSize }, sort: "id:asc") {
      data {
        id
        attributes {
          name
          parent {
            data {
              id
              attributes {
                name
              }
            }
          }
          children {
            data {
              id
              attributes {
                name
                gender
                parent {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;

const DELETE_MEMBER = gql`
  mutation DeleteMember($id: ID!) {
    deleteFamilyMember(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

function FamilyMembers({ isAuthenticated, username, userRole }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5500);

  const { loading, error, data, refetch } = useQuery(GET_MEMBERS, {
    variables: { page: currentPage, pageSize: pageSize },
    fetchPolicy: "network-only",
  });

  const [deleteMemberMutation] = useMutation(DELETE_MEMBER, {
    onCompleted: () => refetch(),
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [rootMemberName, setRootMemberName] = useState("");
  const [noSearchResults, setNoSearchResults] = useState(false);

  useEffect(() => {
    if (data?.familyMembers?.data) {
      // Find the root member before filtering
      const rootMember = data.familyMembers.data.find(
        (member) => !member.attributes.parent?.data || member.attributes.parent?.data === null
      );

      if (rootMember) {
        setRootMemberName(rootMember.attributes.name);
      }

      // Filter members based on partial match for name or ID
      const lowercasedTerm = searchTerm.toLowerCase();
      const filtered = data.familyMembers.data.filter(
        (member) =>
          member.attributes.name.toLowerCase().includes(lowercasedTerm) ||
          member.id.toLowerCase().includes(lowercasedTerm)
      );

      setFilteredMembers(filtered);

      // Show "no results" message when appropriate
      setNoSearchResults(searchTerm.length > 0 && filtered.length === 0);

      // If there's a search term and we found matches
      if (searchTerm && filtered.length > 0) {
        // Find exact or best match member to select
        const exactMatchById = filtered.find((member) => member.id.toLowerCase() === lowercasedTerm);
        const exactMatchByName = filtered.find((member) => member.attributes.name.toLowerCase() === lowercasedTerm);
        const firstMatch = filtered[0];

        // Select the member in this order of preference: exact ID match, exact name match, or first result
        const memberToSelect = exactMatchById || exactMatchByName || firstMatch;

        setSelectedMember(memberToSelect);
        setClickedCell({ id: memberToSelect.id, cellType: "parent" });
      }
    }
  }, [searchTerm, data]);

  const handleMemberClick = (member, cellType) => {
    setSelectedMember(member);
    setClickedCell({ id: member.id, cellType });
  };

  const deleteMember = async (id) => {
    try {
      await deleteMemberMutation({ variables: { id: id } });
    } catch (error) {
      alert("There was an error deleting the member.");
    }
  };

  if (loading)
    return (
      <>
        <img className="m-auto" style={{ width: "60px" }} src={loadingImg} alt="Loading..." />
        <p className="text-center">Loading...</p>
      </>
    );
  if (error) return <p className="text-center text-lg text-red-500">Error fetching data: {error.message}</p>;

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  // Improved buildRows function that handles search context better
  const buildRows = () => {
    if (!filteredMembers || filteredMembers.length === 0) {
      // Show appropriate no results message based on context
      if (noSearchResults) {
        return (
          <tr>
            <td colSpan="2" className="py-2 px-4 border-2 border-gray-200 text-center">
              No member found with that name or ID. Please try again.
            </td>
          </tr>
        );
      } else {
        return (
          <tr>
            <td colSpan="2" className="py-2 px-4 border-2 border-gray-200 text-center">
              No members found. Please add a root member first.
            </td>
          </tr>
        );
      }
    }

    // When searching and we have results, we'll display them directly
    if (searchTerm) {
      // Create a map of all members by ID for easier lookup
      const membersById = {};
      filteredMembers.forEach((member) => {
        membersById[member.id] = member;
      });

      // Render all matched members directly
      return filteredMembers.map((member) => (
        <tr key={member.id}>
          <td
            className="text-sm py-2 px-4 border border-gray-700 font-semibold"
            onClick={() => handleMemberClick(member, "parent")}
            style={{
              cursor: "pointer",
              backgroundColor:
                clickedCell?.id === member.id && clickedCell?.cellType === "parent" ? "#f2f2f2" : "transparent",
              color: clickedCell?.id === member.id && clickedCell?.cellType === "parent" ? "black" : "#333",
            }}
          >
            {member.attributes.name} {/* Optional: Add ID for clarity: (ID: {member.id}) */}
          </td>
          <td className="text-sm py-2 px-4 border border-gray-700 font-semibold">
            {member.attributes.children?.data.map((child) => {
              // Find the full child object from our members list
              const fullChild = membersById[child.id] || child;

              return (
                <div
                  key={child.id}
                  onClick={() => handleMemberClick(fullChild, "child")}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      clickedCell?.id === child.id && clickedCell?.cellType === "child" ? "#f2f2f2" : "transparent",
                    color: clickedCell?.id === child.id && clickedCell?.cellType === "child" ? "black" : "#333",
                    marginBottom: "10px",
                  }}
                >
                  {child.attributes.name}
                </div>
              );
            })}
          </td>
        </tr>
      ));
    }

    // Default behavior (no search) - show the family tree hierarchy
    // Step 1: Find all root members (members without a parent)
    const rootMembers = filteredMembers.filter(
      (member) => !member.attributes.parent?.data || member.attributes.parent?.data === null
    );

    if (rootMembers.length === 0) {
      return (
        <tr>
          <td colSpan="2" className="py-2 px-4 border-2 border-gray-200 text-center">
            No root members found. The hierarchy might be broken.
          </td>
        </tr>
      );
    }

    // Step 2: Build a map of parent ID to children for easier lookup
    const childrenByParentId = {};
    filteredMembers.forEach((member) => {
      if (member.attributes.children && member.attributes.children.data.length > 0) {
        childrenByParentId[member.id] = member.attributes.children.data;
      }
    });

    // Step 3: Create a map of all members by ID for easier lookup
    const membersById = {};
    filteredMembers.forEach((member) => {
      membersById[member.id] = member;
    });

    // Step 4: Function to render a member row
    const renderMemberRow = (member) => {
      return (
        <tr key={member.id}>
          <td
            className="text-sm py-2 px-4 border border-gray-700 font-semibold"
            onClick={() => handleMemberClick(member, "parent")}
            style={{
              cursor: "pointer",
              backgroundColor:
                clickedCell?.id === member.id && clickedCell?.cellType === "parent" ? "#f2f2f2" : "transparent",
              color: clickedCell?.id === member.id && clickedCell?.cellType === "parent" ? "black" : "#333",
            }}
          >
            {member.attributes.name}
          </td>
          <td className="text-sm py-2 px-4 border border-gray-700 font-semibold">
            {member.attributes.children?.data.map((child) => {
              // Find the full child object from our members list
              const fullChild = membersById[child.id] || child;

              return (
                <div
                  key={child.id}
                  onClick={() => handleMemberClick(fullChild, "child")}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      clickedCell?.id === child.id && clickedCell?.cellType === "child" ? "#f2f2f2" : "transparent",
                    color: clickedCell?.id === child.id && clickedCell?.cellType === "child" ? "black" : "#333",
                    marginBottom: "10px",
                  }}
                >
                  {child.attributes.name}
                </div>
              );
            })}
          </td>
        </tr>
      );
    };

    // Step 5: Helper function to create a family hierarchy
    const buildFamilyRows = (rootMember) => {
      const rows = [];

      // Add the root member row
      rows.push(renderMemberRow(rootMember));

      // Function to recursively add children who have their own children
      const addChildrenWithChildren = (parentId) => {
        // Get all children who have their own children
        const children = childrenByParentId[parentId] || [];

        children.forEach((childData) => {
          const child = membersById[childData.id];
          if (child && childrenByParentId[child.id]) {
            rows.push(renderMemberRow(child));
            // Recursively add this child's children who have children
            addChildrenWithChildren(child.id);
          }
        });
      };

      // Start the recursion with the root member
      addChildrenWithChildren(rootMember.id);

      return rows;
    };

    // Step 6: Build rows for all root members and their hierarchies
    let allRows = [];
    rootMembers.forEach((rootMember) => {
      allRows = [...allRows, ...buildFamilyRows(rootMember)];
    });

    return allRows;
  };

  // Count unique parents
  const allParents = new Set();
  filteredMembers.forEach((member) => {
    if (!member.attributes.parent?.data) {
      allParents.add(member.id);
    } else {
      // Add the parent to the set
      allParents.add(member.attributes.parent.data.id);
    }
  });

  const totalFamilies = allParents.size;

  return (
    <div className="container px-4 lg:w-4/5 lg:px-0 mx-auto">
      {data.familyMembers.data.length >= 1 && !searchTerm && (
        <h1 className="mb-5 text-xl md:text-2xl font-medium text-center">Lineage Of {rootMemberName}</h1>
      )}

      {searchTerm && filteredMembers.length > 0 && (
        <h1 className="mb-5 text-xl md:text-2xl font-medium text-center">Search Results for "{searchTerm}"</h1>
      )}

      {/* Search Component */}
      {data.familyMembers.data.length >= 1 && (
        <Search className="w-full xl:w-4/5" searchTerm={searchTerm} onSearchChange={setSearchTerm} />
      )}

      {/* Families Count - only show when not searching */}
      {!searchTerm && (
        <p className="text-sm font-medium mb-4">
          {totalFamilies} {totalFamilies === 1 ? "family found" : "families found"}
        </p>
      )}

      {/* Search results count - only show when searching */}
      {searchTerm && (
        <p className="text-sm font-medium mb-4">
          {filteredMembers.length} {filteredMembers.length === 1 ? "member found" : "members found"}
        </p>
      )}

      {/* Conditionally render the Add Family Member button */}
      {!data?.familyMembers?.data?.some((member) => !member.attributes.parent?.data) && (
        <button
          variant="secondary"
          className="flex items-center text-sm px-4 py-2 rounded border mb-4 bg-indigo-100 hover:bg-gray-200 transition duration-300"
          onClick={openModal}
          title="Add root member"
        >
          <FaUserPlus className="size-4 mr-2" />
          Add root member
        </button>
      )}

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Family Member"
        className="fixed inset-0 bg-white w-full mx-auto my-4 p-6 border max-w-lg border-gray-300 rounded-lg shadow-lg max-height-[400px] overflow-y-scroll"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
      >
        <AddFamilyMemberForm
          parentName={selectedMember?.attributes?.name || ""}
          onSuccess={() => {
            refetch();
            closeModal();
          }}
        />
        <button onClick={closeModal} className="mt-4 bg-gray-300 text-white px-4 py-2 rounded hover:bg-gray-400">
          <IoMdClose />
        </button>
      </Modal>

      {data.familyMembers.data.length >= 1 && (
        <div className="block lg:flex gap-5">
          <div className="w-full mb-5 lg:w-1/3 bg-white-100">
            <div className="overflow-y-scroll" style={{ height: "fit-content", maxHeight: "600px" }}>
              <table className={loading ? `animate-pulse` : `w-full border-collapse`}>
                <thead className="bg-gray-400">
                  <tr>
                    <th className="py-2 px-4 border-2 border-gray-200 bg-gray-200 font-semibold">Parent</th>
                    <th className="py-2 px-4 border-2 border-gray-200 bg-gray-200 font-semibold">Children</th>
                  </tr>
                </thead>
                <tbody>{buildRows()}</tbody>
              </table>
            </div>
          </div>

          {/* Member Details */}
          <div className="w-full lg:w-2/3 bg-white-100 lg:mt-0 mt-5">
            {selectedMember ? (
              <MemberDetails
                memberId={selectedMember?.id}
                member={selectedMember}
                isAuthenticated={isAuthenticated}
                userRole={userRole}
                username={username}
                onDeleteMember={deleteMember}
              />
            ) : (
              <p className="text-center text-lg">Select a member to view details</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FamilyMembers;
