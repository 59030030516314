import React, { useRef, useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import ReactToPrint from "react-to-print";
import { FaPrint } from "react-icons/fa6";
import QRCode from "qrcode.react";
import "./Certificate.css";
import certLogo from "../images/cert-logo.png";
// import signatureImg from "../images/signature.png";
import { GET_MEMBER_WITH_PARENT } from "../utils/familyQueries";

const Certificate = ({ memberId }) => {
  const [lineage, setLineage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [memberData, setMemberData] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const certificateRef = useRef();
  const client = useApolloClient();

  useEffect(() => {
    const controller = new AbortController(); // For cleanup on unmount

    async function fetchLineage() {
      if (!memberId) {
        setLoading(false);
        return;
      }

      try {
        // Start with the selected member
        let currentMemberId = memberId;
        const lineageArray = [];
        let currentMemberData = null;

        // For progress tracking
        const estimatedAncestors = 30; // Base estimation
        let fetchedCount = 0;

        // First fetch just the main member to show basic info quickly
        const { data: initialData } = await client.query({
          query: GET_MEMBER_WITH_PARENT,
          variables: { id: memberId },
          fetchPolicy: "cache-first", // Try cache first for immediate response
        });

        const initialMember = initialData?.familyMember?.data;
        if (initialMember) {
          setMemberData(initialMember.attributes);
          // Show partial rendering with just the member data
          setLoading(false);
          // Continue loading lineage in background
          setLoadingProgress(5); // Start progress at 5%
        }

        // Keep fetching until we reach a member with no parent (root)
        while (currentMemberId && !controller.signal.aborted) {
          const { data } = await client.query({
            query: GET_MEMBER_WITH_PARENT,
            variables: { id: currentMemberId },
            fetchPolicy: "network-only", // For ancestry, ensure we get fresh data
          });

          const member = data?.familyMember?.data;
          if (!member) break;

          // Store the original member's data
          if (currentMemberId === memberId && !currentMemberData) {
            currentMemberData = member.attributes;
            setMemberData(currentMemberData);
          }

          // Add to lineage
          lineageArray.push({
            id: member.id,
            name: member.attributes.name,
          });

          // Update loading progress
          fetchedCount++;
          setLoadingProgress(Math.min(5 + Math.round((fetchedCount / estimatedAncestors) * 95), 99));

          // Move up to parent or exit if no parent
          const parentData = member.attributes.parent?.data;
          currentMemberId = parentData ? parentData.id : null;

          // Small delay to prevent UI freezing with rapid requests
          if (currentMemberId) {
            await new Promise((resolve) => setTimeout(resolve, 50));
          }
        }

        // Set the lineage (reversed to show oldest ancestor first)
        setLineage(lineageArray.reverse());
        if (currentMemberData) {
          setMemberData(currentMemberData);
        }
        setLoadingProgress(100);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching lineage:", err);
        setError(err);
        setLoading(false);
      }
    }

    fetchLineage();

    return () => {
      controller.abort(); // Cancel any in-progress fetches when component unmounts
    };
  }, [memberId, client]);

  // Convert lineage array to just names for display (computed property)
  const lineageNames = lineage.map((item) => item.name);

  // Handle loading states
  if (error)
    return (
      <div className="text-center p-4">
        <p className="text-red-500 font-semibold">Error: {error.message}</p>
        <p>There was an error loading the certificate. Please try again later.</p>
      </div>
    );

  return (
    <div className="">
      <ReactToPrint
        trigger={() => (
          <button
            variant="secondary"
            className="flex gap-2 mb-5 items-center text-xs md:text-sm py-2 px-5 border rounded-lg hover:bg-indigo-50"
            disabled={loading || lineageNames.length === 0}
          >
            <FaPrint title="print certificate" className="size-2 md:size-4 mr-2 cursor-pointer" />
            Print Certificate
          </button>
        )}
        content={() => certificateRef.current}
        documentTitle="Family Chain Certificate"
      />

      {/* Loading indicator */}
      {(loading || loadingProgress < 100) && lineageNames.length === 0 && (
        <div className="text-center mb-4">
          <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500 mb-2"></div>
          <p>Loading certificate data...</p>
          {loadingProgress > 0 && (
            <div className="w-full bg-gray-200 rounded-full h-2.5 max-w-md mx-auto mt-2">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-in-out"
                style={{ width: `${loadingProgress}%` }}
              ></div>
              <p className="text-xs mt-1">Loading ancestry: {loadingProgress}%</p>
            </div>
          )}
        </div>
      )}

      {/* Progress indicator for lineage loading */}
      {!loading && lineageNames.length > 0 && loadingProgress < 100 && (
        <div className="text-center mb-4">
          <p className="text-sm text-gray-600">Loading full ancestry tree: {loadingProgress}%</p>
          <div className="w-full bg-gray-200 rounded-full h-1.5 max-w-md mx-auto">
            <div
              className="bg-green-500 h-1.5 rounded-full transition-all duration-300 ease-in-out"
              style={{ width: `${loadingProgress}%` }}
            ></div>
          </div>
        </div>
      )}

      {/* Certificate content */}
      {memberData && (
        <div ref={certificateRef} className="certificate">
          <div className="certificate-wrapper">
            <div className="certificate-header">
              <img src={certLogo} className="cert-logo w-20 lg:w-[148px] m-auto" alt="cert-logo" />
              <h1 className="certificate-title arabika text-xl lg:text-2xl xl:text-xl text-center font-playwrite">
                بِسْمِ اللهِ الرَّحْمٰنِ الرَّحِيْمِ
              </h1>
              <h4 className=" arabika-big text-xs lg:text-sm xl:text-xl mb-5" dir="rtl">
                هذا ما تم إثباته بالمصادر المعتمدة في علم الأنساب بخصوص نسب{" "}
                <strong className="" style={{ color: "#6baf02" }}>
                  {memberData.name}
                </strong>
                ، والذي تمتد سلسلته كما يلي من الشجرة الأم التي جمعها النسابة الحبيب علي زين العابدين بن عبدالله
                العيدروس.
              </h4>
            </div>
            <div className="certificate-content">
              <div className="lineage flex flex-wrap justify-center font-times mb-10">
                {lineageNames.map((name, index) => (
                  <div className="lineage-item flex items-center" key={index}>
                    <p className="lineage-name text-xs lg:text-sm bg-lime-400 mr-2 border-2 rounded-full p-2">{name}</p>
                    {index < lineageNames.length - 1 && (
                      <p className="mr-2">
                        <span className="indexNumber bg-slate-500 text-white px-4 py-0">{index + 1}</span>
                      </p>
                    )}
                  </div>
                ))}

                {/* Show loading indicator when lineage is still loading */}
                {loadingProgress < 100 && lineageNames.length > 0 && (
                  <div className="lineage-item flex items-center">
                    <p className="lineage-name text-xs lg:text-sm bg-gray-200 mr-2 border-2 rounded-full p-2 animate-pulse">
                      Loading...
                    </p>
                  </div>
                )}
              </div>

              {/* Bio Details Table */}
              <div className="bio-details w-[90%] mx-auto print:w-full">
                <h4 className="lineage-table-title text-xl lg:text-2xl text-center mb-1">البيانات للسادة آل باعلوي </h4>
                <table
                  className="bio-table w-full border-collapse overflow-x-scroll border border-black text-xs lg:text-sm"
                  dir="rtl"
                >
                  <tbody>
                    <tr>
                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">رقم التسجيل</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        RBLW-000{memberId || "N/A"}
                      </td>
                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">مقاطعة</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {memberData.county || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">القبيلة</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {memberData.clan || "N/A"}
                      </td>
                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">الجنسية</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {memberData.nationality || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">الذرية</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {memberData.posterity || "N/A"}
                      </td>
                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">العنوان</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {memberData.address || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="arabika p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        قبيلة الأم
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {memberData.mothers_clan || "N/A"}
                      </td>
                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">البريد الإلكتروني</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {memberData.email || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">تاريخ الميلاد</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {memberData.date_of_birth || "N/A"}
                      </td>

                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">المهنة</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {memberData.occupation || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">مكان الميلاد</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {memberData.place_of_birth || "N/A"}
                      </td>
                      <td className="p-2 border bg-lime-200 border-black text-right" colSpan={2}>
                        <small className="block arabika">تاريخ التسجيل</small>
                      </td>
                      <td className="p-2 border border-black text-left" colSpan={2}>
                        {new Date().toLocaleDateString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="arabika-mid float-end mt-3" style={{ float: "right" }}>
                  الإشراف والتعضيد : الحبيب محمد بن عبدالرحمن السقاف.
                </p>
              </div>
            </div>
            <div className="certificate-footer flex justify-between items-center mt-10">
              <div className="w-3/4">
                <p className="arabika-small text-center" dir="rtl">
                  محمد عبدالله محمد السقاف <br />
                  (خادم أنساب السادة آل باعلوي - شرق إفريقيا)
                </p>
              </div>
              <div className="w-1/4 qr-code-container flex flex-col items-center" style={{ float: "right" }}>
                <QRCode
                  value={`ALBAALAWY FAMILY CERTIFICATE: NAME: -- ${memberData.name} --, EMAIL: -- ${
                    memberData.email || "N/A"
                  } --, DATE OF BIRTH: ${memberData.date_of_birth || "N/A"}, CERTIFIED BY: Albaalawy, VERIFIED BY: `}
                  className="qr-code w-[80px] h-auto"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Certificate;
