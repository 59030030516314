import { gql } from "@apollo/client";

// Get a member and its direct parent
export const GET_MEMBER_WITH_PARENT = gql`
  query GetMemberWithParent($id: ID!) {
    familyMember(id: $id) {
      data {
        id
        attributes {
          name
          email
          date_of_birth
          gender
          is_alive
          date_of_death
          place_of_birth
          occupation
          biographical_information
          mothers_clan
          nationality
          county
          village
          posterity
          branch
          clan
          thigh
          parent {
            data {
              id
              attributes {
                name
              }
            }
          }
          children {
            data {
              id
              attributes {
                name
                gender
              }
            }
          }
        }
      }
    }
  }
`;
