import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import Modal from "react-modal";
import Certificate from "../components/Certificate";
import FamilyMemberForm from "./FamilyMemberForm";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TiUserAdd } from "react-icons/ti";
import { CgNotes } from "react-icons/cg";
import { FaUsers } from "react-icons/fa6";
import { TbCertificate } from "react-icons/tb";
import { FaRegEdit } from "react-icons/fa";
import ConfirmationModal from "./DeleteConfirmationModal";

const GET_MEMBER_DETAILS = gql`
  query GetMemberDetails($id: ID!) {
    familyMember(id: $id) {
      data {
        id
        attributes {
          name
          email
          address
          mobile
          date_of_birth
          place_of_birth
          gender
          is_alive
          nationality
          county
          clan
          mothers_clan
          village
          branch
          posterity
          thigh
          occupation
          date_of_death
          biographical_information
          parent {
            data {
              id
              attributes {
                name
                children {
                  data {
                    id
                    attributes {
                      name
                      gender
                    }
                  }
                }
              }
            }
          }
          children {
            data {
              id
              attributes {
                name
                gender
              }
            }
          }
        }
      }
    }
  }
`;
function MemberDetails({ member, onDeleteMember, isAuthenticated, userRole }) {
  const [activeTab, setActiveTab] = useState("bio");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [parentInfo, setParentInfo] = useState({ id: "", name: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_MEMBER_DETAILS, {
    variables: { id: member.id },
    skip: !member.id,
  });

  console.log("User role is: ", userRole);

  const handleAddChild = () => {
    setParentInfo({
      id: member.id,
      name: data?.familyMember?.data?.attributes?.name || "",
    });
    setIsEditing(false);
    setModalIsOpen(true);
  };

  const handleEditMember = () => {
    setIsEditing(true);
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
    setParentInfo({ id: "", name: "" });
    setIsEditing(false);
  };

  const handleDeleteMember = (id) => {
    id = member.id;
    onDeleteMember(id);
    setIsModalOpen(false);
    refetch();
  };

  const openDeleteModal = () => {
    setIsModalOpen(true);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching member details: {error.message}</p>;

  const memberData = data?.familyMember?.data?.attributes || {};

  const getRelatives = () => {
    const relatives = [];

    if (memberData.parent?.data) {
      relatives.push({
        type: "Parent",
        name: memberData.parent.data.attributes.name,
      });
    }

    const parent = memberData.parent?.data;
    if (parent && parent.attributes.children?.data) {
      const siblings = parent.attributes.children.data.filter((sibling) => sibling.id !== member.id);
      siblings.forEach((sibling) => {
        const siblingType = sibling.attributes.gender === "Male" ? "Brother" : "Sister";
        relatives.push({
          type: siblingType,
          name: sibling.attributes.name,
        });
      });
    }

    if (memberData.children?.data) {
      memberData.children.data.forEach((child) => {
        relatives.push({
          type: child.attributes.gender === "Male" ? "Son" : "Daughter",
          name: child.attributes.name,
        });
      });
    }

    return relatives;
  };

  return (
    <div className="relative mt-5 lg:mt-0">
      <h2 className="text-sm xl:text-lg font-semibold mb-4">
        {memberData.name || "N/A"} - {member.id}
      </h2>

      {/* Tabs Section */}
      <div className="lg:fixed z-10 bg-white lg:bg-white/0 shadow-sm w-full lg:w-1/2">
        <div className="flex space-x-2">
          <button
            className={`flex items-center text-xs md:text-sm p-2 lg:px-6 lg:py-2 ${
              activeTab === "bio" ? "bg-indigo-600 text-white" : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => setActiveTab("bio")}
          >
            <CgNotes className="size-4 mr-2" />
            Bio
          </button>
          <button
            className={`flex items-center text-xs md:text-sm p-2 lg:px-6 lg:py-2 ${
              activeTab === "relatives" ? "bg-indigo-600 text-white" : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => setActiveTab("relatives")}
          >
            <FaUsers className="size-4 mr-2" />
            Relatives
          </button>
          <button
            className={`flex items-center text-xs md:text-sm p-2 lg:px-6 lg:py-2 ${
              activeTab === "certificate" ? "bg-indigo-600 text-white" : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => setActiveTab("certificate")}
          >
            <TbCertificate className="size-4 mr-2" />
            Certificate
          </button>
        </div>
      </div>

      {/* Details Section */}
      <div className="mt-5 lg:mt-20 space-y-4 overflow-y-scroll" style={{ maxHeight: "550px" }}>
        {activeTab === "bio" && (
          <div className="bg-white p-6 rounded-lg shadow border border-gray-300">
            <h4 className="text-xl font-semibold mb-4 pb-2 border-b-2 border-gray-300">Biographical Information</h4>
            <div className="text-xs flex flex-col md:grid md:grid-cols-2 md:gap-4 md:text-sm">
              <div className="flex mb-2">
                <span className="font-medium mr-2">Name:</span> <span>{memberData.name || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Email:</span> <span>{memberData.email || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Address:</span> <span>{memberData.address || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Mobile:</span> <span>{memberData.mobile || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Date of Birth:</span>{" "}
                <span>{memberData.date_of_birth || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Place of Birth:</span>{" "}
                <span>{memberData.place_of_birth || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Gender:</span> <span>{memberData.gender || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Is Alive:</span> <span>{memberData.is_alive ? "Yes" : "No"}</span>
              </div>
              {!memberData.is_alive && (
                <div className="flex mb-2">
                  <span className="font-medium mr-2">Date of Death:</span>{" "}
                  <span>{memberData.date_of_death || "N/A"}</span>
                </div>
              )}
              <div className="flex mb-2">
                <span className="font-medium mr-2">Nationality:</span> <span>{memberData.nationality || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">County:</span> <span>{memberData.county || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Clan:</span> <span>{memberData.clan || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Mother's Clan:</span> <span>{memberData.mothers_clan || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Village:</span> <span>{memberData.village || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Branch:</span> <span>{memberData.branch || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Posterity:</span> <span>{memberData.posterity || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Thigh:</span> <span>{memberData.thigh || "N/A"}</span>
              </div>
              <div className="flex mb-2">
                <span className="font-medium mr-2">Occupation:</span> <span>{memberData.occupation || "N/A"}</span>
              </div>
              <div className="flex mb-2 col-span-2">
                <span className="font-medium mr-2">Bio:</span>{" "}
                <span>{memberData.biographical_information || "N/A"}</span>
              </div>
            </div>
          </div>
        )}

        {activeTab === "relatives" && (
          <div className="bg-white p-4 rounded shadow relatives">
            <h3 className="text-xl font-semibold mb-2">Relatives</h3>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border bg-gray-200 text-left">Relative Type</th>
                  <th className="py-2 px-4 border bg-gray-200 text-left">Name</th>
                </tr>
              </thead>
              <tbody>
                {getRelatives().map((relative, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border">{relative.type}</td>
                    <td className="py-2 px-4 border">{relative.name}</td>
                  </tr>
                ))}
                {getRelatives().length === 0 && (
                  <tr>
                    <td colSpan="2" className="py-2 px-4 border text-center">
                      No relatives found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {userRole !== "Public" && (
              <button
                variant="secondary"
                className="mt-4 px-4 py-2 flex items-center border border-indigo-300 hover:bg-indigo-50 font-semibold rounded"
                onClick={handleAddChild}
              >
                <TiUserAdd className="size-4 mr-2" />
                Add Child
              </button>
            )}
          </div>
        )}

        {activeTab === "certificate" && (
          <div className="w-full bg-white p-6 rounded-lg shadow border border-gray-300">
            <Certificate memberId={member.id} />
          </div>
        )}
      </div>

      {/* Add Family Member Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleModalClose}
        contentLabel={isEditing ? "Edit Family Member" : "Add Family Member"}
        ariaHideApp={false}
        className="fixed inset-0 bg-white w-full max-w-2xl mx-auto my-4 p-6 border border-gray-300 rounded-lg shadow-lg h-auto overflow-y-scroll z-50"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 z-40"
      >
        <FamilyMemberForm
          memberId={isEditing ? member.id : null}
          parentInfo={isEditing ? null : parentInfo}
          onSuccess={() => {
            handleModalClose();
            refetch();
          }}
          closeModal={handleModalClose}
        />
      </Modal>
      <div className="absolute top-0 right-0 flex space-x-2">
        {userRole !== "Public" && (
          <>
            <button
              variant="outline"
              className="text-indigo-600 bg-indigo-100 text-sm p-2 text-center border rounded hover:bg-white hover:border-indigo-400"
              onClick={handleEditMember}
              title={`Edit member ${data?.familyMember?.data?.attributes?.name}`}
            >
              <FaRegEdit className="size-4" />
            </button>

            <button
              variant="destructive"
              className="text-red-600 bg-red-100 text-sm p-2 text-center border rounded hover:bg-white hover:border-red-400"
              onClick={openDeleteModal}
              title={`Delete member ${data?.familyMember?.data?.attributes?.name}`}
            >
              <RiDeleteBin6Line className="size-4" />
            </button>
          </>
        )}
        <ConfirmationModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          onConfirm={handleDeleteMember}
          message={`Are you sure you want to Delete member ${data?.familyMember?.data?.attributes?.name}?`}
          title="Confirm Delete"
        />
      </div>
    </div>
  );
}
export default MemberDetails;
