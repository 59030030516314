import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getStrapiURL } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../images/baalawy-logo.png";

const LoginForm = ({ setIsAuthenticated, setUsername }) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Validation schema using Yup
  const validationSchema = Yup.object({
    identifier: Yup.string().required("Username or email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      identifier: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        // First check for empty fields (though Formik should prevent this)
        if (!values.identifier || !values.password) {
          throw new Error("Please enter both username/email and password");
        }

        const url = getStrapiURL("/auth/local");

        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        const responseData = await res.json();

        if (!res.ok) {
          // Handle specific error types from Strapi
          if (res.status === 400) {
            if (responseData.error?.message?.includes("identifier")) {
              throw new Error("Invalid username or email");
            } else if (responseData.error?.message?.includes("password")) {
              throw new Error("Incorrect password");
            } else if (responseData.error?.message?.includes("Identifier or password invalid")) {
              throw new Error("The provided credentials are incorrect");
            }
          }
          // Default error message if we can't determine a specific error
          throw new Error(responseData.error?.message || `Login failed: ${res.status}`);
        }

        const { jwt, user } = responseData;

        // Save the token and username to local storage
        localStorage.setItem("token", jwt);
        localStorage.setItem("username", user.username || "");

        // Set a default role in case role fetching fails
        let role = "Authenticated";

        try {
          // Try to fetch the user's role
          const roleUrl = getStrapiURL(`/api/users/${user.id}?populate=role`);
          console.log("Fetching role from:", roleUrl);

          const roleRes = await fetch(roleUrl, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          });

          console.log("Role response status:", roleRes.status);

          if (roleRes.ok) {
            const roleData = await roleRes.json();
            console.log("Role data:", roleData);

            // Check different possible paths for role information
            if (roleData.role?.name) {
              role = roleData.role.name;
            } else if (roleData.role?.type) {
              role = roleData.role.type;
            } else if (roleData.data?.role?.name) {
              role = roleData.data.role.name;
            } else if (roleData.data?.role?.type) {
              role = roleData.data.role.type;
            } else {
              console.log("Role data structure:", JSON.stringify(roleData));
            }
          } else {
            console.warn("Failed to fetch role, using default:", role);
            const errorText = await roleRes.text();
            console.warn("Role error response:", errorText);
          }
        } catch (roleError) {
          // Just log the error and continue with default role
          console.error("Error fetching user role:", roleError);
        }

        // Save role regardless of whether fetching succeeded
        localStorage.setItem("userRole", role);
        console.log("Saved user role:", role);

        // Update authentication state and username
        setIsAuthenticated(true);
        setUsername(user.username);

        navigate("/family-members");
        toast.success("Login successful");
      } catch (error) {
        console.error("Login error:", error);
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  // Helper function to determine if a field has an error
  const hasError = (fieldName) => formik.touched[fieldName] && formik.errors[fieldName];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-indigo-100 ">
      <div className="w-1/4 h-full login-bg"></div>
      <img className="login-logo w-[100px] max-w-[100px] h-auto mb-4 z-20 border-x-lime-700" src={logo} alt="logo" />

      <div className="w-4/5 mx-auto max-w-[300px] p-8 bg-white shadow-lg rounded-lg">
        <h1 className="text-2xl font-bold mb-6 text-center">Login</h1>
        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <div className="form-group">
            <label htmlFor="identifier" className="block text-sm font-medium text-gray-700">
              Username/Email
            </label>
            <input
              type="text"
              name="identifier"
              id="identifier"
              placeholder="Enter your username or email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.identifier}
              className={`mt-1 block w-full px-3 py-2 border ${
                hasError("identifier") ? "border-red-500" : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
            {hasError("identifier") && <p className="mt-1 text-sm text-red-600">{formik.errors.identifier}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Enter your password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              className={`mt-1 block w-full px-3 py-2 border ${
                hasError("password") ? "border-red-500" : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
            />
            {hasError("password") && <p className="mt-1 text-sm text-red-600">{formik.errors.password}</p>}
          </div>
          <button
            type="submit"
            disabled={isSubmitting || !formik.isValid}
            className={`w-full py-2 px-4 ${
              isSubmitting || !formik.isValid ? "bg-blue-300 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"
            } text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
          >
            {isSubmitting ? "Logging in..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
