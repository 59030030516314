import { gql } from "@apollo/client";
import { client } from "./apolloClient";

// Get basic information about a member
export const GET_MEMBER_BASIC = gql`
  query GetMemberBasic($id: ID!) {
    familyMember(id: $id) {
      data {
        id
        attributes {
          name
          parent {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

// Mutation to explicitly set a parent relationship
export const SET_PARENT_RELATIONSHIP = gql`
  mutation SetParentRelationship($id: ID!, $parentId: ID!) {
    updateFamilyMember(id: $id, data: { parent: $parentId }) {
      data {
        id
      }
    }
  }
`;

// Function to ensure a member's parent relationship is correct
export async function fixParentRelationship(memberId, parentId) {
  if (!memberId || !parentId) {
    console.error("Missing member ID or parent ID", { memberId, parentId });
    return false;
  }

  try {
    console.log(`Fixing relationship: member=${memberId}, parent=${parentId}`);

    // Direct update approach
    const result = await client.mutate({
      mutation: SET_PARENT_RELATIONSHIP,
      variables: {
        id: memberId,
        parentId: parentId,
      },
    });

    console.log("Fixed parent relationship result:", result);
    return true;
  } catch (error) {
    console.error("Error fixing parent relationship:", error);
    return false;
  }
}
